import React from 'react'
import bannerImage from '../images/banner/banner3.webp';
function banner() {
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  return (
    <div>
      {/* <img src={`${baseURL}/images/Banner-latest-svg.svg`} className="banner_imgage" alt="Banner Image" /> */}
       <img src={bannerImage} className="banner_imgage" alt="Banner Image" />
    </div>
  )
}

export default banner
