import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const [isDesignMenuOpen, setIsDesignMenuOpen] = useState(false);
  const [isProductMenuOpen, setIsProductMenuOpen] = useState(false);

  const location = useLocation(); // Hook to get the current route

  // Check if the current route is part of the Product submenu
  useEffect(() => {
    if (location.pathname === '/add-product' || location.pathname === '/product-page') {
      setIsProductMenuOpen(true);
    }
  }, [location.pathname]);

  // Check if the current route is part of the Design submenu
  useEffect(() => {
    if (location.pathname === '/add-design' || location.pathname === '/design-page') {
      setIsDesignMenuOpen(true);
    }
  }, [location.pathname]);

  const toggleProductMenu = () => {
    setIsProductMenuOpen(!isProductMenuOpen);
  };

  const toggleDesignMenu = () => {
    setIsDesignMenuOpen(!isDesignMenuOpen);
  };

  return (
    <div className="sidebar">
      <ul>
        <li className={location.pathname === '/admin-dashboard' ? 'active' : ''}>
          <Link to="/admin-dashboard">Orders</Link>
        </li>
        <li>
          <div onClick={toggleProductMenu} style={{ cursor: 'pointer' }}>
            Product {isProductMenuOpen ? '▼' : '▶'}
          </div>
          {isProductMenuOpen && (
            <ul>
              <li className={location.pathname === '/add-product' ? 'active' : ''}>
                <Link to="/add-product">Add Product</Link>
              </li>
              <li className={location.pathname === '/product-page' ? 'active' : ''}>
                <Link to="/product-page">Edit Product</Link>
              </li>
            </ul>
          )}
        </li>
        <li>
          <div onClick={toggleDesignMenu} style={{ cursor: 'pointer' }}>
            Design {isDesignMenuOpen ? '▼' : '▶'}
          </div>
          {isDesignMenuOpen && (
            <ul>
              <li className={location.pathname === '/add-design' ? 'active' : ''}>
                <Link to="/add-design">Add Design</Link>
              </li>
              <li className={location.pathname === '/design-page' ? 'active' : ''}>
                <Link to="/design-page">Edit Design</Link>
              </li>
            </ul>
          )}
        </li>
        <li className={location.pathname === '/user' ? 'active' : ''}>
          <Link to="/user">User</Link>
        </li>
        {/* <li className={location.pathname === '/profile' ? 'active' : ''}>
          <Link to="/profile">Profile</Link>
        </li>
        <li className={location.pathname === '/settings' ? 'active' : ''}>
          <Link to="/settings">Settings</Link>
        </li> */}
      </ul>
    </div>
  );
};

export default Sidebar;
