import React, {useEffect} from 'react'
import Nav from './nav';
import Banner from './banner';
// import Slider from './slider';
import Product from './product';
import Addvertise from './addvertise';
import Footer from './footer';
import Topbar from './top';
import SlickSlider from './slick_slider';
import Subscribe from './subscribe';
import Best_seller from './best_seller';
function Main() {

  useEffect(() => {
    document.title = "Vareprint | Home"; // Change the title dynamically
  }, []);
  return (
    <div>
      <Nav />
      <Banner />
      <Topbar />
      {/* <SlickSlider /> */}
      <Product />
      <Addvertise />
      <Best_seller />
     <Subscribe />
      <Footer />
    </div>
  )
}

export default Main
