// Test2.jsx (PitchPrint App Display)
import React, { useEffect } from 'react';

const Test2 = () => {
  useEffect(() => {
    // Ensure PitchPrint client is initialized and display the app
    if (window.ppclient) {
      // Show the PitchPrint app in the specified div
      window.ppclient.showApp({
        divId: 'iframe_editor', // The ID of the div where the app should be shown
        displayMode: 'mini', // Keep it in mini mode
      });
    } else {
      console.error('PitchPrint client is not initialized.');
    }

    // Cleanup function to hide the app when this component is unmounted
    return () => {
      if (window.ppclient) {
        window.ppclient.hideApp();
      }
    };
  }, []);

  return (
    <div>
      <h2>Customize Your Design</h2>
      <div className="row editor-canvas-row">
        <div className="col-sm-8">
          <div id="iframe_editor" className="iframe_editor">
            <div id="pp_loader_div">
              <img
                src="https://pitchprint.io/rsc/images/loaders/spinner_new.svg"
                alt="Loader"
              />
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="pp_main_btn_sec" id="pp_main_btn_sec"></div>
          <button type="submit" className="addtocart">
            Submit
          </button>
          <p className="note2">
            *We will send you the final artwork for approval before we proceed to print.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Test2;
