import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import {jwtDecode} from 'jwt-decode'; // Note the updated import

function App() {
  const handleLoginSuccess = (credentialResponse) => {
    const decodedData = jwtDecode(credentialResponse.credential); // Updated usage
    console.log("User data:", decodedData);
    const { email, name, picture } = decodedData;
    console.log("Email:", email);
    console.log("Name:", name);
    console.log("Picture:", picture);
  };

  const handleLoginFailure = () => {
    console.log("Login failed");
  };

  return (
    <GoogleOAuthProvider clientId="963405453586-cceukdgmtgrmj7utl78tqvsusafb573v.apps.googleusercontent.com">
      <div className="App">
        <h2>Sign in with Google</h2>
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginFailure}
        />
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
