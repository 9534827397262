import React from 'react';
import './App.css';
import './mobile.css';
import './desktop.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Main from './component/main';
import Preview from './component/preview';
// import Banner from './component/banner';
import Design from './component/design';
import Design_for_notbook from './component/design_for_notbook'
import Editor from './component/editor';
import Cart from './component/cart';
import Login_User from './component/login';
import ScrollToTop from './component/scrolltotop';
import Payment_response from './component/payment_response';
import Slider2 from './component/slick_slider';
import Payment_success from './component/payment_success';
import Payment_failed from './component/payment_fail';
import Editor2 from './component/editor2';
import Contact_us from './component/contact_us';
import About from './component/about';
import Term_conditions from './component/terms_condition';
import Privacy_policy from './component/privacy_policy';
import ProfilePage from './component/profile';
import Invoice_admin from './feadmin/invoice_admin';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Test from './component/test';
import Test2 from './component/test2';


import User_details from './component/user_details';
import Checkout from './component/checkout';
import Invoice_Form from './feadmin/invoice_form';



import Main2 from './feadmin/main'; 

// import Layout from './layout';
import DesignPage from  '././feadmin/designpage';
import Productpage from  '././feadmin/productpage';
import Layout from '././feadmin/layout';
import Dashboard from '././feadmin/dashboard';
import Addproduct from '././feadmin/addproduct';
import Editproduct from '././feadmin/editproduct';
import Profile from '././feadmin/profile';
import User from './feadmin/admin_user';
import Settings from '././feadmin/settings';
import EditDesign from '././feadmin/editdesign';
import Invoice from './feadmin/invoice';
import Order_form from './feadmin/order_form';


import Rating_login from './feadmin/rating_login'; 
import Rating from './feadmin/rating'; 

function App() {
  return (
   
    <div className="App">
    <ScrollToTop />
    <Routes>
      
          <Route path="/" element={<Main />} />
          <Route path="/slick" element={<Slider2 />} />
          <Route path="/test" element={<Test />} />
          <Route path="/test2" element={<Test2 />} />
          <Route path="design/:productId" element={<Design />} />
          <Route path="notebook/:productId" element={<Design_for_notbook />} />
          <Route path="preview/:designId" element={<Preview />} />
          <Route path="/editor/:designId/:did/:pid/:oid" element={<Editor />} />
          <Route path="/editor-f/:designId/:did/:pid/:oid" element={<Editor2 />} />
          <Route path="cart-page/" element={<Cart />} />
          <Route path="/login-page" element={<Login_User />} />
          <Route path="/payment-response" element={<Payment_response />} />
          <Route path="/user-dashboard" element={<User_details />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/checkout/:guest" element={<Checkout />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/profile-page" element={<ProfilePage />} />

          <Route path="/privacy-policy" element={<Privacy_policy />} />
          <Route path="/terms-condition" element={<Term_conditions />} />
          <Route path="/contact-us" element={<Contact_us />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/get-invoice/:invoiceID" element={<Invoice_admin />} />
        
          <Route path="/admin-login" element={<Main2 />} />
          <Route path="/admin-dashboard" element={<Layout />} />
          <Route path="/add-design" element={<Dashboard />} />
          <Route path="/add-product" element={<Addproduct />} />
          <Route path="/edit-product/:pid" element={<Editproduct />} />
          <Route path="/product-page" element={<Productpage />} />
          <Route path="/design-page" element={<DesignPage />} />
          <Route path="/user" element={<User />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/edit-design/:did" element={<EditDesign />} />
          <Route path="/edit-design/:did" element={<EditDesign />} />

         
          <Route path="/order-form/:orderID" element={<Order_form />} />
          <Route path="/Rating-login" element={<Rating_login />} />
          <Route path="/Rating/:id" element={<Rating />} />


          <Route path="/payment_success" element={<Payment_success />} />
          <Route path="/payment_failed" element={<Payment_failed />} />
        </Routes>
      </div>
  );
}

export default App;
