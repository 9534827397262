import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation(); // Get the current route's pathname

  useEffect(() => {
    // This will scroll the page to the top (0,0) when pathname changes
    window.scrollTo(0, 0);
  }, [pathname]); // Dependency is on the route's pathname, scroll when it changes

  return null; // This component does not render anything in the DOM
};

export default ScrollToTop;

