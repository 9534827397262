import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './preview.css';
import Nav from './nav';
import Footer from './footer';
import Top from './top';
import he from 'he';

import { Modal, Button, Table } from 'react-bootstrap';
import { useUser } from './user'; // Adjust the import path as needed
const baseURL = process.env.REACT_APP_API_BASE_URL;

const Preview = () => {

  const selectSize = useRef(null);
  const textcolor= useRef(null);
  const { designId } = useParams();
  const navigate = useNavigate();
  const [design, setDesign] = useState(null);
  const [product, setProduct] = useState(null);
  const [preview, setPreview] = useState([]);
  const [selectedImage, setSelectedImage] = useState('');
  const [selectpath, setselectedPath]= useState('');
  const [selectedImage1, setSelectedImage1] = useState('');
const [reviews, setReviews] = useState([]);
const [formData, setFormData]= useState()
const [ sizeDesignId, setsizeDesignId] = useState(null);
  const { userId } = useUser();
  const [message, setMeassage]= useState('');
  const [tmessage, setTMeassage]= useState('');
  const [colormessage, setColorMeassage]= useState('');
  const [uploadFileMessage, setuploadFileMessage] = useState('');
  const [qrnote, setqrnote] = useState('');

  const [order, setOrder] = useState({
    pid: '',
    did: '',
    price: '',
    total: '',
    quantity: '',
    min_qty:'',
    gst: '',
    size: '',
    gst_amount: '',
    withoutgstprice: '',
    size:'',
    white: {},
    black: {},
    text: '',
    font: '',
    upload_file: null,
    tshirt_size:'',
    qr_code_link:'',
    
    });

    const [show, setShow] = useState(false);
    const [showPitch, setshowPitch] = useState(false);
    const handleClosepitch = () => setshowPitch(false);
    const [showVedio, setShowVedio] = useState(false);



    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose2 = () => setShowVedio(false);
    const handleShowVedio = () => setShowVedio(true);
  
      // fetchDesignDetails(designId);
   
  
      useEffect(() => {
        document.title = "Vareprint | Preview"; // Change the title dynamically
      }, []);

  useEffect(() => {
    axios.get(`${baseURL}/preview/${designId}`)
      .then(response => {
        const { designs, product_preview } = response.data;

        if (designs.length > 0) {
          const designData = designs[0];
          setDesign(designData);

          // Calculate prices
          const { price, gst, min_qty } = designData;
          const totalPrice = price * min_qty;
          const gstAmount = (totalPrice * gst) / 100;
          const finalPrice = totalPrice + gstAmount;

          // Update state with fetched design details and prices
          setOrder({
            pid: designData.product,
            did: designData.did,
            designId: designData.designid,
            total: finalPrice,
            price: designData.price,
            quantity: designData.min_qty,
            gst: designData.gst,
            withoutgstprice: totalPrice,
            gst_amount: gstAmount,
            white: designData.white, 
            black: designData.black,
        
          });
          setProduct(designData.product);

          // Set previews array from product_preview
          if (product_preview && product_preview.previews) {
            setPreview(product_preview.previews);
          }
        }
      })
      .catch(error => {
        console.error('Error fetching product details:', error);
      });

      initPitchPrint();

  }, [designId]);

  const initPitchPrint = () => {
    // if (typeof window.jQuery === 'undefined') {
    //   console.error('jQuery is not available');
    //   return;
    // }

    // if (typeof PitchPrintClient === 'undefined') {
    //   console.error('PitchPrintClient is not available');
    //   return;
    // }

    // console.log('PitchPrintClient is available');

    // const ppclient = new PitchPrintClient({
    //   displayMode: 'mini',
    //   apiKey: '6b6b93e26e66bf557cc4d35683fc3507', // Replace with your API key
    //   designId: '184d9ed1d4fab0c245195e1fedfb84a9', // Use the designId from the URL params
    //   custom: true,
    //   isvx: true,
    // });

    // window.ppclient = ppclient;

    // const appValidated = () => {
    //   const loaderDiv = document.getElementById('pp_loader_div');
    //   if (loaderDiv) {
    //     loaderDiv.style.display = 'none';
    //   }
    //   if(showPitch){
    //   ppclient.showApp();
    //   }
    // };

    // const projectSaved = (_val) => {
    //   const _data = _val.data;
    //   if (_data && _data.previews && _data.previews.length) {
    //     const previewDiv = document.getElementById('pp_preview_div');
    //     if (previewDiv) {
    //       previewDiv.innerHTML = _data.previews.reduce(
    //         (_str, _prev) => `${_str}<img src="${_prev}" alt="Preview Image"/>`,
    //         ''
    //       );
    //     }
    //     const imageName1 = _data.previews[0];
    //     const imageName2 = _data.previews[1];
    //     const projectId = _data.projectId;
    //     const pages = _data.previews.length;

    //     console.log('Image Name 1:', imageName1);
    //     console.log('Image Name 2:', imageName2);
    //     console.log('Project ID:', projectId);
    //     console.log('Pages:', pages);

    //     setFormData({
    //       ...formData,
    //       img_name: projectId,
    //       imagename1: imageName1,
    //       imagename2: imageName2,
    //       user_id: userId || '', // Update user_id with userId or empty string if null
    //       pages,
    //     });

    //     // triggerCartButtonClick();
    //   }
    // };

    // ppclient.on('app-validated', appValidated);
    // ppclient.on('project-saved', projectSaved);
  };
   
    const triggerCartButtonClick = () => {
      const cartButton = document.querySelector('.addtocart');
      if (cartButton) {
        cartButton.click();
      }
    };


  const Star = ({ filled }) => {
    return (
        <span
            style={{ 
                color: filled ? '#FFD700' : '#C0C0C0', // gold for filled, gray for empty
                fontSize: '15px'
            }}
        >
            ★
        </span>
    );
};

const StarRating = ({ rating }) => {
    const totalStars = 5;
    return (
        <div>
            {Array.from({ length: totalStars }, (v, i) => (
                <Star
                    key={i}
                    filled={i < rating}
                />
            ))}
        </div>
    );
};



  useEffect(() => {
    if (product) {
      axios.get(`${baseURL}/review/${product}`)
        .then(response => {
          const reviewData = response.data
          console.log('review-data:', reviewData);
          setReviews(reviewData); // Assuming the API response contains an array of reviews
        })
        .catch(error => {
          console.error('Error fetching reviews:', error);
        });
    }
  }, [product]);


  const orderPlaced = (e) => {
    e.preventDefault();

    if (order.pid == 10 && !order.color) {
      setColorMeassage('* Required');
      return; // Prevent the API call and form submission
    }

    if (order.pid == 24 && !order.color) {
      setColorMeassage('* Required');
      return; // Prevent the API call and form submission
    }

    if (order.pid == 10 && !order.tshirt_size) {
      setTMeassage('* Required');
      return; // Prevent the API call and form submission
    }
   
  
    // Proceed with the API call only if the size is selected (or pid is not 10)
    axios.post(`${baseURL}/order_placed`, order)
      .then(response => {
        console.log('Order placed successfully:', response.data);
        const orderId = response.data.orderId;
  
        if (response.status === 200) {
          const sizeId = sizeDesignId || order.designId;
          const newUrl = `/editor/${sizeId}/${order.did}/${order.pid}/${orderId}`;
          window.location.href = newUrl;
        }
      })
      .catch(error => {
        console.error('Error placing order:', error);
      });
  };
  
  const orderPlaceddiy = (e) => {
    e.preventDefault();

    if (order.pid == 10 && !order.color) {
      setColorMeassage('* Required');
      return; // Prevent the API call and form submission
    }


    if (order.pid == 24 && !order.color) {
      setColorMeassage('* Required');
      return; // Prevent the API call and form submission
    }


   


    if (order.pid == 10 && !order.tshirt_size) {
      setTMeassage('* Required');
      return; // Prevent the API call and form submission
    }
  
    if (order.did == '140' || order.did == '141' || order.did == '142' || order.did == '145' || order.did == '148') {
      if (!order.size) {
        // selectSize.current.focus();
        selectSize.current.style.border = '1px solid red';
        selectSize.current.classList.add('error-vibrate'); // Add error class with !important border
        setMeassage('* Required');
        return; // Prevent the API call and form submission
      }
    }
    
    axios.post(`${baseURL}/order_placed`, order)
      .then(response => {
        console.log('Order placed successfully:', response.data);
        const orderId = response.data.orderId;
  
        if (response.status === 200) {
          
          if(sizeDesignId){
            const newUrl = `/editor-f/${sizeDesignId}/${order.did}/${order.pid}/${orderId}`;
              window.location.href = newUrl;
          }else{
            const newUrl = `/editor-f/${order.designId}/${order.did}/${order.pid}/${orderId}`;
            window.location.href = newUrl;
          }
         
        
        
        }
      })
      .catch(error => {
        console.error('Error placing order:', error);
      });
  };


  const orderPlacedDirectCart = (e) => {
    e.preventDefault();
    
    // Check conditions before proceeding
    if (order.did === 26 && !order.upload_file) {
      setuploadFileMessage('* Required');
      return; // Stop the function execution
    }
  
    if (order.did === 95 && !order.qr_code_link) {
      setqrnote('* Required');
     
    }

    if (order.pid == 24 && !order.color) {
      setColorMeassage('* Required');
      return; // Prevent the API call and form submission
    }
    if (order.pid == 10 && !order.color) {
      setColorMeassage('* Required');
      return; // Prevent the API call and form submission
    }
    if (order.pid == 10 && !order.tshirt_size) {
      setTMeassage('* Required');
      return; // Prevent the API call and form submission
    }
    // if (order.pid == 10 && !order.tshirt_size) {
    //   console.error('Error: Size is required for this product.');
    //   alert('Please select a size for this product.'); // Display an alert to the user
    //   return; // Prevent the API call and form submission
    // }
  
    // Create a FormData object to hold the order data
    const formData = new FormData();
    
    // Append the order data as a JSON string
    formData.append('order', JSON.stringify(order));
    
    // Append the user ID
    formData.append('userId', userId);
  
    // Send the form data via axios POST request
    axios.post(`${baseURL}/order_placed_direct_cart`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      if (response.status === 200) {
        // Navigate to the cart page after successful order placement
        navigate(`/cart-page`);
      }
    })
    .catch(error => {
      console.error('Error placing direct order:', error);
    });
  };

  
  
  const ChangeTshirtColor = (color) => {
    axios.get(`${baseURL}/preview/color/${designId}/${color}`)
      .then(response => {
        const { design, price } = response.data;
        setSelectedImage(design.image);
        setselectedPath('images/design');
        setOrder(prevOrder => ({
          ...prevOrder,
          price,
          color:color,
          designId: design.designId,
          total: price * prevOrder.quantity,
          withoutgstprice: design.price * prevOrder.quantity,
          gst_amount: (design.price * prevOrder.quantity * design.gst) / 100
        }));
        setColorMeassage('');
      })
      .catch(error => {
        console.error('Error fetching color preview:', error);
      });
  };
  
    const handleQuantityChange = async (e) => {
    setOrder({ ...order, quantity: e.target.value });
    const newQuantity = e.target.value;
    if (newQuantity) {
      axios.get(`${baseURL}/get-price/${designId}`)
        .then(response => {
          const { price, gst } = response.data;
          const totalPrice = price * newQuantity;
          const gstAmounttotalPrice = (totalPrice * gst) / 100;
          const finalPrice = totalPrice + gstAmounttotalPrice;

          setOrder({
            ...order,
            quantity: newQuantity,
            total: finalPrice,
            withoutgstprice: totalPrice,
            gst_amount: gstAmounttotalPrice
          });
        })
        .catch(error => {
          console.error('Error fetching price:', error);
        });
    }
  };


  const handleTextChange = (e) => {
    setOrder(prevOrder => ({
      ...prevOrder,
      text: e.target.value,
      qr_code_link:e.target.value,
    }));

    if(designId=='176'){
    if (textcolor.current) {
      textcolor.current.style.color = 'white'; // Change the text color
    }
  }

  };

  const handleFontChange = (e) => {
    setOrder(prevOrder => ({
      ...prevOrder,
      font: e.target.value
    }));
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setOrder(prevOrder => ({
        ...prevOrder,
        upload_file: file // Store the File object directly
      }));
      
    }
  };
  
  const handleImageClick = (image, path) => {
    setSelectedImage(image);
    setselectedPath(path);
    console.log(image,path);
  };


  const handleChangeTshirtSize = (e) => {
    setOrder(prevOrder => ({
      ...prevOrder,
      size: e.target.value,
      tshirt_size: e.target.value,
    }));
    setTMeassage('');
  };

  const handleSelectChange = async (event) => {
    const { name, value } = event.target; // Get the name and value of the changed element
    const selectedDesignId = order.did; // Get the design ID from the order state
    
    if (name === 'size') {
      // Handle size change
      const selectedColor = value; // Get the selected color value (white, black, yellow)
      
      // Update the order state with the new color selection
      setOrder(prevOrder => ({
        ...prevOrder,
        color: selectedColor
      }));

     
      
        
       
      // Call the API to get the updated price, GST amount, and total based on the selected size
      try {
        const response = await axios.post(`${baseURL}/update-price`, {
          color: selectedColor,
          did: selectedDesignId,
          quantity: order.quantity // Send the current quantity
        });
        
        const { price, gst_amount, total, design } = response.data;
  
        setSelectedImage(design.image);
        setselectedPath('images/design');
        // Update the order state with the new values
        setOrder(prevOrder => ({
          ...prevOrder,
          price,
          gst_amount,
          total,
          designId: design.designId,
          size:design.size, // Update the designId with the one from the response
        }));
        setsizeDesignId(design.designId); // Correctly set sizeDesignId

        selectSize.current.style.border = '1px solid black';
        setMeassage('');
        selectSize.current.classList.remove('error-vibrate'); // Add error class with !important border
        console.log('Selected Color:', selectedColor);
        console.log('Updated Price:', price);
        console.log('GST Amount:', gst_amount);
        console.log('Total:', total);
        console.log('Design ID from Response:', design.designId);
  
      } catch (error) {
        console.error('Error fetching updated price:', error);
      }
    } else if (name === 'quantity') {
      // Handle quantity change
      const newQuantity = value; // Get the new quantity value
  
      if (!order.color) {
        // If size is not selected, show an error message
        alert('Please select a size before changing the quantity.');
        return;
      }
  
      // Update the quantity in the order state
      setOrder(prevOrder => ({
        ...prevOrder,
        quantity: newQuantity
      }));
  
      // Call the API to get the updated price, GST amount, and total based on the quantity
      try {
        const response = await axios.post(`${baseURL}/update-price`, {
          color: order.color, // Use the current selected color
          did: selectedDesignId,
          quantity: newQuantity,
        });
        
        const { price, gst_amount, total, design } = response.data;
  
        // Update the order state with the new values
        setOrder(prevOrder => ({
          ...prevOrder,
          price,
          gst_amount,
          total,
          designId: design.designId, 
          size:design.image,// Update the designId with the one from the response
        }));
        setsizeDesignId(design.designId); // Correctly set sizeDesignId
  
        console.log('Updated Quantity:', newQuantity);
        console.log('Updated Price:', price);
        console.log('GST Amount:', gst_amount);
        console.log('Total:', total);
        console.log('Design ID from Response:', design.designId);
  
      } catch (error) {
        console.error('Error fetching updated price:', error);
      }
    }
  };
  
  
  
  
  
  
  if (!design) {
    return <div>Loading...</div>;
    // if (ppclient) {
    //   ppclient.hideApp();
    // }
  }
  
  return (
    <React.Fragment>
      <Nav />
      <Top />
      <div className='container'>
        <ul className='filter_ul'>
          <li className='top_ul'><Link className='top_ul' to={`/`}>Home</Link></li>
          <li>&nbsp;/&nbsp;</li>
          <li><Link className='top_ul' to={`/design/${design.pid}`}>{design.dname}</Link></li>
        </ul>
      </div>
      <div className='container preview-container'>
        <div className='row'>
          <div className='col-md-7'>
            <div className='row preview-row'>
              <div className='col-sm-4 preview_div'>
                <img
                  src={`${baseURL}/images/design/${design.images}`}
                  alt='preview1'
                  onClick={() => handleImageClick(design.images, 'images/design')}
                  className='preview-images'
                />
                {preview.length > 0 && preview[0] && (
               


                    <React.Fragment>
                    <img
                      src={`${baseURL}/images/${preview[0]}`}
                      alt='preview2'
                      onClick={() => handleImageClick(preview[0], 'images')}
                      className='preview-images'
                    />
                    <img
                      src={`${baseURL}/images/${preview[1]}`}
                      alt='preview3'
                      onClick={() => handleImageClick(preview[1], 'images')}
                      className='preview-images'
                    />
                  </React.Fragment>
                
                  
                )}
              </div>
              <div className='col-sm-8'>
                {selectedImage ? (
                  <img
                    src={`${baseURL}/${selectpath}/${selectedImage}`}
                    alt={`Design ${design.designid}`}
                    className="design-image"
                  />
                
                 
                ) : (
                  <img
                  src={`${baseURL}/images/design/${design.images}`}
                  alt={`Design ${design.designid}`}
                  className="design-image"
                />
                )
              }
<p className='preview-text' ref={textcolor} style={{ fontFamily: order.font }}>{order.text}</p>

                 <p className='note_for_shown'><span className='note_'>Note:</span> Image shown is for reference only.</p>
              </div>
              <div className='col-sm-4 preview_div2'>
                <img
                  src={`${baseURL}/images/design/${design.images}`}
                  alt='preview1'
                  onClick={() => handleImageClick(design.images, 'images/design')}
                  className='preview-images'
                />
                {preview.length > 0 && preview[0] && (
               


                    <React.Fragment>
                    <img
                      src={`${baseURL}/images/${preview[0]}`}
                      alt='preview2'
                      onClick={() => handleImageClick(preview[0], 'images')}
                      className='preview-images'
                    />
                    <img
                      src={`${baseURL}/images/${preview[1]}`}
                      alt='preview3'
                      onClick={() => handleImageClick(preview[1], 'images')}
                      className='preview-images'
                    />
                  </React.Fragment>
                
                  
                )}
              </div>
            </div>
           
          </div>
          <div className='col-md-5'>
            <form>
              <div className='desc-card'>
                <p className='preview_design_name'>{design.dname}</p>
                {/* <p className='preview_dec'>{he.decode(design.prodds1)}</p> */}
                <p className='preview_dec'>{he.decode(design.prodds1)}</p>

                  {/* <p className='preview_dec'>{he.decode(design.prodds1)}</p> */}
                  

                <input
                  type='hidden'
                  name="pid"
                  value={order.pid}
                />
                <input
                  type='hidden'
                  name='did'
                  value={order.did}
                />
                <p className='preview_price'>&#8377;{order.total}/-</p>


                {(design.type === '3') && 
  (design.product !== 24 && design.product !== 10) && (
    <>
      {design.whiteSize && (
        <>
          <label className='label-size'>Size</label>
          <br />
          <select name='size' ref={selectSize} className='select-design-size' onChange={handleSelectChange} required>
            <option value="">Select Size</option>
            <option value="white">{design.whiteSize}</option>
            {design.blackSize && (
              <option value="black">{design.blackSize}</option>
            )}
            {design.yellowSize && (
              <option value="yellow">{design.yellowSize}</option>
            )}
          </select>
          <br />
          <p className='select-size-error'>{message}</p>
        </>
        
      )}
    </>
  )
  
  
}

<p className='label_quantity'>Quantity:</p>
<input
  type='text'
  name='quantity'
  className='quantity_input_change'
  value={order.quantity}
  onChange={design.type === '3' ? handleSelectChange : handleQuantityChange}
/>

{(design.product === '5' && design.designid === '') && (
  <>
    <br />
    <label className='label_quantity'>Text:</label>
    <input
      type='text'
      name='text'
      className='form-control text_for_notebook'
      value={order.text}
      onChange={handleTextChange}
    
    />
      <label  className='label_quantity'>Select Font:</label>
   <select name='font' className='form-control font_for_notebook'   value={order.font}
                  onChange={handleFontChange}>
   <option value=''>Select Font</option>
    <option value='Futura'>Futura</option>
    <option value='Monotype Corsiva'>Monotype Corsiva</option>
   </select>
  </>
)}



   {(design.did == '26' ) && (
  <>
    <br />
    <br />
    <label className='upload-label'>Upload File:</label>
   <input type='file' name='upload_file' className='form-control upload_file'  onChange={handleFileChange}  required/>
   <p className='note-error-uploadfile'>{uploadFileMessage}</p>
  </>
)}
                 {(design.pid == '10' || design.pid == '24') && (
  <>
    <br />
    <div className='order-info'>
      <br />
                  <label className='select-color-label'>Select Color</label>
                  <div className='color-buttons'>
                    <Button id="button1"
                      variant="outline-dark"
                      onClick={() => ChangeTshirtColor('white')}
                      className={`color-button ${order.color === 'white' ? 'active' : ''}`}
                    >
                      
                    </Button>
                    <Button id="button2"
                      variant="outline-dark"
                      onClick={() => ChangeTshirtColor('black')} 
                      className={`color-button ${order.color === 'black' ? 'active' : ''}`}
                    >
                      
                    </Button>
                  </div>
                  <p className='note-error-for-color'>{colormessage}</p>
                </div>
  </>


)}
{(design.pid == '10') && (
  <p className='view-chart' onClick={handleShow}> View chart</p>
)}
{(design.pid == '10') && (
        <>
     
       
    
         
          <select
            className="form-control size-t"
            name="size"
            value={order.size}
            onChange={handleChangeTshirtSize}
          >
            <option value="">Select Size</option>
            <option value="XS">XS</option>
            <option value="S">S</option>
            <option value="M">M</option>
            <option value="L">L</option>
            <option value="XL">XL</option>
            <option value="XXL">XXL</option>
          </select>
          <p className='note-error-t-size'>{tmessage}</p>
        </>
       
       
      )}
      
      {design.did == '95' && (
  <>
    <br />
    <label className="add-text-qr-code">Add Text</label>
    <input
      type="text"
      name="qr_code_link"
      placeholder="Enter your QR code link"
      value={order.qr_code_link || ''}
      onChange={handleTextChange}
      className="form-control qr-code-text"
    />
    <p className='qr-note'>{qrnote}</p>
  </>
)}

            {/* <div className="color-buttons">
                <button className="color-button black"></button>
                <button className="color-button white"></button>
            </div> */}
       
                <input type='hidden' name='price' value={order.price} />
                <input type='hidden' name='gst' value={order.gst} />
                <input type='hidden' name='withoutgstprice' value={order.withoutgstprice} />
                <input type='hidden' name='gst_amount' value={order.gst_amount} />
                <input type='hidden' name='total' value={order.total} />
                <br />
                {/* <button type='submit' className='personalisation_button' name="submit">CLICK HERE FOR PERSONALISATION</button> */}




                {design.op_type === 'b' ? (
  <button
    type='submit'
    className='personalisation_button'
    name="submit"
    onClick={orderPlacedDirectCart}
  >
    ADD TO CART
  </button>
) : (
  design.type === '3' ? (
    <button
      type='submit'
      className='personalisation_button'
      name="submit"
      onClick={orderPlaceddiy}
    >
      CLICK HERE FOR PERSONALISATION
    </button>
  ) : (
    <button
      type='submit'
      className='personalisation_button'
      name="submit"  
      onClick={orderPlaced}
    >
      CLICK HERE FOR PERSONALISATION
    </button>
    
  )
)}

              </div>
            </form>
           <hr />
           {/* <p className='show-vedio-title' onClick={handleShowVedio}>How to customize a product and place your order </p> */}
           
           <br />
         <div>
          {/* <p className='review-title'>Reviews</p> */}
  {reviews.map((review) => (
    <div key={review.rid} className="review-item">
      <StarRating rating={review.rating} />
      <p className='review-ds1'>{review.review}</p>
      <p className='review-user'>-&nbsp;{review.username}</p>
     <hr />
    </div>
  ))}
</div>

          </div>

        </div>
       
      </div>
      
      <br />


     

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>T-shirts Measurement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table className='table-size-chart'>
            <thead>
              <tr style={{ background: '#e6e6e6' }}>
                <th className="text-center">Size</th>
                <th className="text-center">Chest (in inches)</th>
                <th className="text-center">Body Length (in inches)</th>
                <th className="text-center">Sleeve Length (in inches)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">XS</td>
                <td className="text-center">36</td>
                <td className="text-center">25</td>
                <td className="text-center">7.5</td>
              </tr>
              <tr>
                <td className="text-center">S</td>
                <td className="text-center">38</td>
                <td className="text-center">26</td>
                <td className="text-center">8</td>
              </tr>
              <tr>
                <td className="text-center">M</td>
                <td className="text-center">40</td>
                <td className="text-center">27</td>
                <td className="text-center">8.25</td>
              </tr>
              <tr>
                <td className="text-center">L</td>
                <td className="text-center">42</td>
                <td className="text-center">28</td>
                <td className="text-center">8.75</td>
              </tr>
              <tr>
                <td className="text-center">XL</td>
                <td className="text-center">44</td>
                <td className="text-center">29</td>
                <td className="text-center">9.25</td>
              </tr>
              <tr>
                <td className="text-center">XXL</td>
                <td className="text-center">46</td>
                <td className="text-center">30</td>
                <td className="text-center">9.75</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
     
      <Modal show={showVedio} onHide={handleClose2}>
        <Modal.Body className='modal-content'>
        <iframe id="videoFrame" width="100%" height="200" src={`${baseURL}/images/howorder.mp4`} frameborder="0" allowfullscreen></iframe>
        
        </Modal.Body>
       
      </Modal>
      <Footer />
    </React.Fragment>
  );
};

export default Preview;
