import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Sidebar from './sidebar';
import Navbar from './navbar';
import Footer from './footer';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const Design = () => {
  const [users, setUsers] = useState([]); // State for storing users (or products)
  const [count, SetCount] = useState(''); // State for storing users (or products)
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  useEffect(() => {
    axios.get(`${baseURL}/user`) // Update endpoint to match your API
      .then(response => {
        const userData = response.data.user;
        const userCount = response.data.userCount;
        setUsers(userData || []); // Default to empty array if response.data is undefined
        SetCount(userCount);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);

  // Search and filter users
  const filteredUsers = users.filter(user =>
    (user.firstName?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
    (user.lastName?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
    (user.email?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
    (user.contact?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
    (user.email?.toLowerCase() || '').includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleDelete = (userId) => {
    // Delete user by ID
    axios.delete(`${baseURL}/delete-user/${userId}`) // Ensure this is the correct API endpoint
      .then(response => {
        // Update the state to remove the deleted user
        setUsers(users.filter(user => user.pid !== userId));
        // Optionally reset pagination
        if (currentPage > Math.ceil((users.length - 1) / itemsPerPage)) {
          setCurrentPage(currentPage - 1);
        }
      })
      .catch(error => {
        console.error('Error deleting user:', error);
      });
  };

  return (
    <div className="layout">
      <Sidebar />
      <div className="main-content">
        <Navbar />
        <div className="content">
          <div className='container'>
            <h2>Users ({count})</h2>
            <input
              type="text"
              placeholder="Search by Name or Description..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input-main"
            />
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Contact</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length > 0 ? (
                  currentItems.map(user => (
                    <tr key={user.pid}>
                     
                        
                      <td>{`${user.firstName} ${user.lastName}`}</td>
                     
                      <td>{user.email || 'No email'}</td>
                      <td>{user.contact || 'No Description'}</td>
                     
                    
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">No users found</td>
                  </tr>
                )}
              </tbody>
            </table>
            
            <div className="pagination">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>Page {currentPage} of {totalPages}</span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Design;
