/* global PitchPrintClient */

import React, { useState, useEffect } from 'react';
import Nav from './nav';
import logoImage from '../images/logo/vareprint.webp';

import Footer from './footer';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useUser } from './user'; // Adjust the import path as needed
import './editor.css';
const baseURL = process.env.REACT_APP_API_BASE_URL;

function Editor() {
  const { userId } = useUser(); // Get userId from context
  const navigate = useNavigate();
  const { designId, did, pid, oid } = useParams();
  const [design, setDesign] = useState({ pname: '' });
  const [formData, setFormData] = useState({
    pid: pid,
    did: did,
    order_id: oid,
    pname: '',
    img_name: '',
    imagename1: '',
    imagename2: '',
    pages: '',
    user_id: userId || '', // Initialize user_id with userId or empty string if null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchDesign = () => {
      axios
        .get(`${baseURL}/design-fetch/${did}`)
        .then((response) => {
          const designData = response.data.designdetails[0];
          setDesign(designData);
          setFormData((prevState) => ({
            ...prevState,
            pname: designData.pname,
          }));
        })
        .catch((error) => {
          console.error('Error fetching design details:', error);
        });
    };

    const initPitchPrint = () => {
      if (typeof window.jQuery === 'undefined') {
        console.error('jQuery is not available');
        return;
      }

      if (typeof PitchPrintClient === 'undefined') {
        console.error('PitchPrintClient is not available');
        return;
      }

      console.log('PitchPrintClient is available');

      const ppclient = new PitchPrintClient({
        apiKey: '6b6b93e26e66bf557cc4d35683fc3507', // Replace with your API key
        designId: designId, // Use the designId from the URL params
        custom: true,
        isvx: true,
      });

      window.ppclient = ppclient;

      const appValidated = () => {
        const loaderDiv = document.getElementById('pp_loader_div');
        if (loaderDiv) {
          loaderDiv.style.display = 'none';
        }
        ppclient.showApp();
      };

      const projectSaved = (_val) => {
        const _data = _val.data;
        if (_data && _data.previews && _data.previews.length) {
          const previewDiv = document.getElementById('pp_preview_div');
          if (previewDiv) {
            previewDiv.innerHTML = _data.previews.reduce(
              (_str, _prev) => `${_str}<img src="${_prev}" alt="Preview Image"/>`,
              ''
            );
          }
          const imageName1 = _data.previews[0];
          const imageName2 = _data.previews[1];
          const projectId = _data.projectId;
          const pages = _data.previews.length;

          console.log('Image Name 1:', imageName1);
          console.log('Image Name 2:', imageName2);
          console.log('Project ID:', projectId);
          console.log('Pages:', pages);

          setFormData({
            ...formData,
            img_name: projectId,
            imagename1: imageName1,
            imagename2: imageName2,
            user_id: userId || '', // Update user_id with userId or empty string if null
            pages,
          });

          triggerCartButtonClick();
        }
      };

      ppclient.on('app-validated', appValidated);
      ppclient.on('project-saved', projectSaved);
    };

    initPitchPrint();
    fetchDesign();
  }, [designId, did, pid, oid, userId]); // Include userId in dependencies array

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${baseURL}/order-update`, formData)
      .then((response) => {
        console.log('Data saved successfully:', response.data);
        if (response.status === 200) {
          navigate(`/cart-page`);
        }
      })
      .catch((error) => {
        console.error('Error saving data:', error);
      });
  };

  const triggerCartButtonClick = () => {
    const cartButton = document.querySelector('.addtocart');
    if (cartButton) {
      cartButton.click();
    }
  };

  // Check if userId is null before rendering
  if (!userId) {
    return <div>Loading...</div>; // or render a loading spinner, etc.
  }

  return (
    <React.Fragment>
      <Nav />
      <div className='container'>
        {/* <div className='row editor_vareprint_logo_row'>
        <Link to={`/`}>
          <img src={logoImage} alt='varerprint Logo' className='editor_vareprint_logo'/>
          </Link>
        </div> */}
        {/* <ul className='filter_ul'>
          <Link to={`/`}>
            <li>Home</li>
          </Link>
        </ul> */}
      </div>
      <div className='container'>
        <div className='row editor-canvas-row'>
          <div className='col-sm-8'>
            <div id='iframe_editor2' className='iframe_editor2'>
              <div id='pp_loader_div'>
                <img src='https://pitchprint.io/rsc/images/loaders/spinner_new.svg' alt='Loader' />
              </div>
            </div>
          </div>
          <div className='col-sm-4'>
            <form onSubmit={handleSubmit}>
              <div className='pp_main_btn_sec' id='pp_main_btn_sec'></div>
              {/* Hidden inputs and other form elements */}
              <button type='submit' className='addtocart'>
                Submit
              </button>
            </form>
            <p className='note2'>*We will send you the final artwork for approval before we proceed to print.</p>
          </div>
        </div>
      </div>
     
    </React.Fragment>
  );
}

export default Editor;
